import { orgsKeys } from "src/data/organizations/queries/organizationQueryCache"
import {
  TOrganizationAnalyticsEventQueryParams,
  TOrganizationAnalyticsMetricQueryParams,
  TOrganizationAnalyticsStateQueryParams,
} from "src/data/organizations/types/organizationTypes"
import { useMinutQuery } from "src/hooks/minutQuery"

export function useFetchOrganizationAnalytics({
  orgId,
  params,
}: {
  orgId: string
  params: TOrganizationAnalyticsEventQueryParams
}) {
  return useMinutQuery<
    `/organizations/{organization_id}/analytics/events`,
    "403"
  >({
    queryKey: orgsKeys.analyticsEvents(orgId, params),
    queryPath: `/organizations/${orgId}/analytics/events`,
    filters: params,
  })
}

export function useFetchOrganizationAnalyticsStates({
  orgId,
  params,
}: {
  orgId: string
  params: TOrganizationAnalyticsStateQueryParams
}) {
  return useMinutQuery<
    `/organizations/{organization_id}/analytics/states`,
    "403"
  >({
    queryKey: orgsKeys.analyticsStates(orgId, params),
    queryPath: `/organizations/${orgId}/analytics/states`,
    filters: params,
  })
}

export function useFetchOrganizationAnalyticsMetrics({
  orgId,
  params,
}: {
  orgId: string
  params: TOrganizationAnalyticsMetricQueryParams
}) {
  return useMinutQuery<
    `/organizations/{organization_id}/analytics/metrics`,
    "403"
  >({
    queryKey: orgsKeys.analyticsMetrics(orgId, params),
    queryPath: `/organizations/${orgId}/analytics/metrics`,
    filters: params,
  })
}
