import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"

import { colorScale, interpolateColors } from "src/ui/colors"

type PieChartProps = {
  data: { name: string; y: number }[]
  title?: string
  tooltip?: {
    decimals?: number
    unit?: string
  }
  size?: number
  showLegend?: boolean
}

export function PieChart(params: PieChartProps) {
  const series: Highcharts.Options["series"] = [
    {
      type: "pie",
      data: params.data,
    },
  ]

  const startColor = colorScale.gaff[300]
  const endColor = colorScale.gaff[500]

  const options = {
    accessibility: { enabled: false },
    credits: { enabled: false },
    title: { text: params.title ?? "" },
    chart: {
      type: "pie",
    },
    plotOptions: {
      pie: {
        size: params.size ?? 120,
        dataLabels: {
          enabled: false,
        },
        colors: interpolateColors(startColor, endColor, params.data.length),
        showInLegend: true,
      },
    },
    exporting: {
      enabled: false, // Remove export button
    },
    legend: {
      enabled: params.showLegend ?? true,
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
      useHTML: true,
      labelFormatter: function (this: Highcharts.Point): string {
        let result = `<div>${this.name}:</div>`
        result += `<div>${this.y} homes`
        if (this.percentage) {
          result += ` (${this.percentage.toFixed(1)}%)`
        }
        result += "</div>"
        return result
      },
    },
    series,
    tooltip: {
      useHTML: true,
      padding: 8,
      shared: true,
      shadow: true,
      outside: true,
      pointFormat: "<b>{point.y} ({point.percentage:.1f}%)</b>",
    },
  }

  return (
    <HighchartsReact
      containerProps={{ style: { height: "100%" } }}
      highcharts={Highcharts}
      options={options}
    />
  )
}
