import styled from "styled-components"

import { useGetUser } from "src/data/user/hooks/useGetUser"
import { BarChart } from "src/ui/Graphs/BarChart"
import { TBarChartMultiSeriesData } from "src/ui/Graphs/graphTypes"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { spacing } from "src/ui/spacing"

export function ParadiseOrganizationAnalyticsEventGraph({
  isLoading,
  isError,
  data,
}: {
  isLoading: boolean
  isError: boolean
  data:
    | {
        name: string
        data: {
          timestamp: string
          number_of_events: number
        }[]
      }[]
    | undefined
}) {
  const timezone = "utc" // Hardcode this to UTC since orgs can span multiple timezones
  const user = useGetUser()

  if (isError) {
    return <MBanner type="error">Error fetching analytics events</MBanner>
  }

  if (isLoading) {
    return (
      <div>
        <MSkeleton
          variant="rect"
          width="100%"
          height="calc(400px - 2rem)"
          style={{ marginTop: spacing.M, marginBottom: spacing.M }}
        />
      </div>
    )
  }

  // Remap the data to the format expected by the BarChart component
  const formattedData = data?.map<TBarChartMultiSeriesData>((e) => {
    return {
      name: e.name,
      data: e.data.map((d) => {
        return [new Date(d.timestamp).getTime(), d.number_of_events]
      }),
    }
  })

  if (!formattedData || formattedData.length === 0) {
    return (
      <MBanner type="info">
        No data for organization and time period found
      </MBanner>
    )
  }

  return (
    <Wrapper>
      <BarChart
        dataSeriesType="multi"
        data={formattedData}
        timezone={timezone}
        clockType={user.clock_type ?? "24"}
        showLegend={true}
        stackSeries={true}
        tooltip={{
          disabled: true,
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  gap: ${spacing.M};
`
