import { useMemo, useState } from "react"
import styled from "styled-components"

import { endOfDay, startOfDay, subDays } from "date-fns"

import { IGraphDateRange } from "src/components/Homes/DeviceDetails/Overview/DeviceGraphs"
import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseOrganizationAnalyticsEventGraph } from "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationAnalytics/ParadiseOrganizationAnalyticsEventsGraph"
import { ParadiseOrganizationAnalyticsTop10ByEvents } from "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationAnalytics/ParadiseOrganizationAnalyticsTop10List"
import { mapFilterGroupsToHomeEventTypes } from "src/data/events/logic/eventsLogic"
import { homeEventFilterOptions } from "src/data/events/logic/homeEventConstants"
import { THomeEventFilterGroup } from "src/data/events/types/homeEventTypes"
import { useFetchOrganizationAnalytics } from "src/data/organizations/queries/analyticsQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { DateRangePicker } from "src/ui/DateRangePicker/DateRangePicker"
import { DropdownSelect } from "src/ui/DropdownSelect/DropdownSelect"
import { spacing } from "src/ui/spacing"
import { dateToUTCISOStringWithoutTzConversion } from "src/utils/l10n"

export function ParadiseOrganizationAnalyticsEvents({
  orgId,
}: {
  orgId: string
}) {
  const { _t } = useTranslate()

  const [dateRange, setDateRange] = useState({
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
  })
  const start = dateToUTCISOStringWithoutTzConversion(
    startOfDay(dateRange.startDate)
  )
  const end = dateToUTCISOStringWithoutTzConversion(endOfDay(dateRange.endDate))

  function handleDateRangeChange({ startDate, endDate }: IGraphDateRange) {
    setDateRange({ startDate, endDate })
  }

  const [filter, setFilter] = useState<{
    value: THomeEventFilterGroup | null
    label: string
  }>({
    value: null,
    label: "Event type",
  })

  const eventTypeFilter = useMemo(() => {
    if (!filter.value) return []
    return mapFilterGroupsToHomeEventTypes([filter.value])
  }, [filter])

  const fetchAnalyticsEvents = useFetchOrganizationAnalytics({
    params: {
      from: start,
      to: end,
      event_type: eventTypeFilter.length
        ? eventTypeFilter.join(",")
        : undefined,
    },
    orgId,
  })

  const availableFilterGroups = homeEventFilterOptions
    .map((e) => ({
      value: e.value,
      label: _t(e.label),
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  return (
    <>
      <ParadiseDetailsSection title="Events">
        <FilterWrapper>
          <DateRangePicker
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            onDateRangeChange={handleDateRangeChange}
            dateRangeProps={{
              minBookingDays: 2,
            }}
            defaultPresetKey="DAY"
            calendarPlacement="top-end"
          />

          <EventFilter
            filter={filter}
            setFilter={setFilter}
            availableFilterGroups={availableFilterGroups}
          />
        </FilterWrapper>

        <ParadiseOrganizationAnalyticsEventGraph
          isLoading={fetchAnalyticsEvents.isLoading}
          isError={fetchAnalyticsEvents.isError}
          data={fetchAnalyticsEvents.data?.events_per_day}
        />
      </ParadiseDetailsSection>
      <ParadiseDetailsSection title="Top 10 homes by event">
        <ParadiseOrganizationAnalyticsTop10ByEvents
          data={fetchAnalyticsEvents.data?.events_by_top_homes}
        />
      </ParadiseDetailsSection>
    </>
  )
}

function EventFilter({
  filter,
  setFilter,
  availableFilterGroups,
}: {
  filter: { value: THomeEventFilterGroup | null; label: string }
  setFilter: (filter: {
    value: THomeEventFilterGroup | null
    label: string
  }) => void
  availableFilterGroups: { value: THomeEventFilterGroup; label: string }[]
}) {
  return (
    <DropdownSelect
      label={filter.label ?? "Event type"}
      options={availableFilterGroups}
      placeholder="Search"
      selectedValue={filter.value}
      onClearSelection={() => {
        setFilter({
          value: null,
          label: "Event type",
        })
      }}
      onChange={({ option }) => {
        setFilter({
          value: option.value,
          label: option.label,
        })
      }}
      debounceDelay={0}
    />
  )
}

const FilterWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: ${spacing.M};
  gap: ${spacing.M};
`
