import { paths } from "@minuthq/minut-api-types/schema"

import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import { Routes } from "src/router/routes"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"

type TTop10ListData =
  paths["/organizations/{organization_id}/analytics/events"]["get"]["responses"]["200"]["content"]["application/json"]["events_by_top_homes"]

export function ParadiseOrganizationAnalyticsTop10ByEvents({
  data,
}: {
  data: TTop10ListData | undefined
}) {
  const headers = [
    <div key={"home-name"}>Home name</div>,
    <div key={"count"}>Number of events</div>,
  ]

  if (!data || data.length === 0) {
    return (
      <MBanner type="info">
        No data for organization and time period found
      </MBanner>
    )
  }

  const rows = data.map(({ home_name, number_of_events, home_id }) => (
    <>
      <InternalLink to={Routes.ParadiseHome.location(home_id)}>
        {home_name}
      </InternalLink>
      <div>{number_of_events}</div>
    </>
  ))

  return <ParadiseTable header={headers} rows={rows} />
}
