import { useState } from "react"
import styled from "styled-components"

import { paths } from "@minuthq/minut-api-types/schema"

import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import { useFetchOrganizationAnalyticsMetrics } from "src/data/organizations/queries/analyticsQueries"
import { Routes } from "src/router/routes"
import { DropdownSelect } from "src/ui/DropdownSelect/DropdownSelect"
import {
  TableColumn,
  TTableSort,
  useTableColumns,
} from "src/ui/GridTable/useTableColumns/useTableColumns"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { spacing } from "src/ui/spacing"

type TMetric = "temperature" | "humidity" | "sound"
type TMetricsItem =
  paths["/organizations/{organization_id}/analytics/metrics"]["get"]["responses"]["200"]["content"]["application/json"]["homes"][number]

const metricLabels: Record<TMetric, string> = {
  temperature: "Temperature",
  humidity: "Humidity",
  sound: "Sound",
}

const metricUnits: Record<TMetric, string> = {
  temperature: "°C",
  humidity: "%",
  sound: "dBa",
}

const DEFAULT_METRIC = "temperature"

export function ParadiseOrganizationAnalyticsTop10ByMetric({
  orgId,
}: {
  orgId: string
}) {
  const availableFilters = Object.values(metricLabels)
  const [filter, setFilter] = useState<TMetric>(DEFAULT_METRIC)
  const [sort, setSort] = useState<"asc" | "desc">("desc")

  const tableColumns: TableColumn<TMetricsItem>[] = [
    {
      value: "home_name",
      label: "Home name",
      columnWidth: "auto",
      renderCell: ({ home_name, home_id }) => {
        return (
          <InternalLink to={Routes.ParadiseHome.location(home_id)}>
            {home_name}
          </InternalLink>
        )
      },
    },
    {
      value: "metric_value",
      label: metricLabels[filter],
      columnWidth: "min-content",
      sortButtonSpacing: "compact",
      enableSort: true,
      renderCell: ({ metric_value }) => {
        return (
          <div>
            {metric_value.toFixed(1)} {metricUnits[filter]}
          </div>
        )
      },
    },
  ]

  const fetchAnalyticsMetrics = useFetchOrganizationAnalyticsMetrics({
    orgId,
    params: {
      sort_by: filter,
      sort_order: sort,
    },
  })

  const { headerElements, rows } = useTableColumns({
    columns: tableColumns,
    data: fetchAnalyticsMetrics.data?.homes,
    sort: { id: "metric_value", order: sort },
    onSortChange: (sort: TTableSort) => {
      setSort(sort.order)
    },
  })

  return (
    <Wrapper>
      <MetricSelector
        filter={filter}
        setFilter={setFilter}
        availableFilters={availableFilters}
      />
      {rows && rows.length === 0 ? (
        <MBanner type="info">
          No data for organization and time period found
        </MBanner>
      ) : (
        <ParadiseTable header={headerElements} rows={rows ?? []} />
      )}
    </Wrapper>
  )
}

function MetricSelector({
  filter,
  setFilter,
  availableFilters,
}: {
  filter: TMetric
  setFilter: (filter: TMetric) => void
  availableFilters: string[]
}) {
  const options = availableFilters.map((filter) => ({
    value: filter.toLowerCase(),
    label: filter,
  }))

  return (
    <DropdownSelect
      label={metricLabels[filter]}
      options={options}
      selectedValue={filter}
      onClearSelection={() => {
        setFilter(DEFAULT_METRIC)
      }}
      onChange={({ option }) => {
        setFilter(option.value as TMetric)
      }}
      debounceDelay={0}
    />
  )
}

const Wrapper = styled.div`
  display: grid;
  gap: ${spacing.M};
`
